import "./App.scss";
import { ErrorBoundary } from "react-error-boundary";

//Pages import
import Home from "./pages/Home/Home";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Shipments from "./pages/Shipments/Shipments.jsx";
import { ShipmentInfo } from "./pages/Shipments/ShipmentInfo";
import Settings from "./pages/Settings/Settings.jsx";
import { Error } from "./components/Error";
import { Login } from "./components/login/Login";
import { ForgotPassword } from "./components/login/ForgotPassword";
import { ResetPassword } from "./components/login/ResetPassword";
import { Clients } from "./pages/Clients/Clients";
import { NewClient } from "./pages/Clients/NewClient";
import { Users } from "./pages/Users/Users";
import { NewUser } from "./pages/Users/NewUser";
import { NewSubuser } from "./pages/Clients/Subusers/NewSubuser";
import Layout from "./components/Layout";
import { Billing } from "./pages/Billing/Billing";
import { BillingInvoice } from "./pages/Billing/Billing";
import { ErrorBoundaryComponent } from "./components/ErrorBoundaryComponent";

import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Test comment

export default function App() {
  return (
    <>
      <div id="main-container">
        <Routes ErrorBoundary={ErrorBoundaryComponent}>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/edit-client/:id" element={<NewClient />} />
            <Route path="/clients/edit-client" element={<NewClient />} />
            <Route
              path="/clients/edit-client/:id/subuser/:subuserId"
              exact
              element={<NewSubuser />}
            />
            <Route path="/shipments">
              <Route index element={<Shipments />} />
              <Route path="/shipments/:filter" element={<Shipments />} />
              <Route path="/shipments/edit/:id" element={<ShipmentInfo />} />
            </Route>
            <Route path="/billing">
              <Route index element={<Billing />} />
              <Route path="/billing/:id" element={<BillingInvoice />} />
            </Route>
            <Route path="/reports" element={<ComingSoon />} />
            <Route path="/users">
              <Route index element={<Users />} />
              <Route path="/users/edit/:id" element={<NewUser />} />
              <Route path="/users/edit" element={<NewUser />} />
            </Route>
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<Error />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
}
