import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import "styled-components";
import { token } from "./Utils";
/************************GENERAL DATA FETCHING*************************** */

const cookies = new Cookies();
const URL = process.env.REACT_APP_API_URL;

export const UserData = () => {
  const [userInfo, setUser] = useState([]);

  const getData = async () => {
    const response = await fetch(`${URL}/admin/profile`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token()}` }
    });
    const data = await response.json();
    setUser(data);
  };
  useEffect(() => {
    getData();
  }, []);

  return userInfo;
};

export const ShipmentsData = () => {
  const [shipments, setShipments] = useState([]);
  ///?page=2&offset=2
  const getData = async () => {
    const response = await fetch(`${URL}/admin/shipments`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`,
        Accept: 'application/json',
      },
    });
    const data = await response.json();
    setShipments(data);
  };
  useEffect(() => {
    getData();
  }, []);

  return shipments;
};

export const getAllClients = async () => {
  try {
    const resp = await fetch(`${URL}/admin/clients_all`, {
      method: "GET",
      headers:{
        "Authorization": `Bearer ${token()}`
      }
    });

    const data = await resp.json();

    if(resp.status == 200){
      // console.log(data)
      return data.clients
    }
  } catch (e) {
    console.error(e)
  }
}
