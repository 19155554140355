import moment from "moment";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export const formatDate = (date) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return dd + "-" + mm + "-" + yyyy;
};

export const URL = process.env.REACT_APP_API_URL;

let curToken = "";
export const token = () => {
  if (!curToken || curToken.length < 1) {
    curToken = cookies.get("token");
  }
  return curToken;
};
export const resetToken = () => {
  curToken = "";
};

let curRole = "";
export const role = () => {
  if (!curRole || curRole.length < 1) {
    curRole = cookies.get("role");
  }
  return curRole;
};
export const resetRole = () => {
  curRole = "";
};

export const today = moment().toDate();
export const last_week = moment().subtract(1, "week").endOf("week").toDate();
export const last_month = moment().subtract(1, "month").endOf("month").toDate();
export const last_30_days = moment().subtract(30, "days").toDate();
export const last_3_months = moment().subtract(3, "months").toDate();
export const year_to_date = moment().startOf("year").toDate();

export const LoadingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        width: "10px",
        height: "10px",
        margin: "auto",
        display: "block",
        shapeRendering: "auto",
      }}
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#ffffff"
        strokeWidth="7"
        r="47"
        strokeDasharray="221.48228207808043 75.82742735936014"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
};

const formatMomentDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const getDateRangeFilter = (range) => {
  let val = "";
  let today = new Date();
  let startDate, endDate;
  switch (range) {
    case "next_week":
      startDate = today;
      endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      val = `${formatMomentDate(startDate)},${formatMomentDate(endDate)}`;
      break;
    case "last_30_days":
      startDate = new Date(new Date().setDate(today.getDate() - 30));
      endDate = today;
      val = `${formatMomentDate(startDate)},${formatMomentDate(endDate)}`;
      break;
    case "last_3_months":
      startDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of the previous month
      val = `${formatMomentDate(startDate)},${formatMomentDate(endDate)}`;
      break;
    case "year_to_date":
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = today;
      val = `${formatMomentDate(startDate)},${formatMomentDate(endDate)}`;
      break;
    default:
      val = "";
      break;
  }
  return val;
};


export const shipmentStatusOptions = [
  { value: "all", label: "All Shipments" },
  { value: "released", label: "Released" },
  { value: "submitted", label: "Awaiting Arrival" },
  {
    value: "pending documents",
    label: "Documents Required",
  },
  { value: "PENDING RELEASE", label: "Pending Release" },
  { value: "CBP INTENSIVE EXAM", label: "CBP Intensive Exam" },
  { value: "CBP VACIS EXAM", label: "CBP Vacis Exam" },
  { value: "CBP DOCS REVIEW HOLD", label: "CBP Docs Review Hold" },
  { value: "FDA EXAM", label: "FDA Hold/Exam" },
  { value: "MAY PROCEED", label: "FDA May Proceed" },
  { value: "REVIEW", label: "FDA Under Review" },
  { value: "ISF HOLD", label: "ISF Hold" },
];

export const homeShipmentStatusOptions = [
  { value: "all", label: "All Shipments" },
  { value: "released", label: "Released" },
  {
    value: "pending documents",
    label: "Documents Required",
  },
  { value: "PENDING RELEASE", label: "Pending Release" },
  { value: "CBP INTENSIVE EXAM", label: "CBP Intensive Exam" },
  { value: "CBP VACIS EXAM", label: "CBP Vacis Exam" },
  { value: "CBP DOCS REVIEW HOLD", label: "CBP Docs Review Hold" },
  { value: "FDA EXAM", label: "FDA Hold/Exam" },
  { value: "MAY PROCEED", label: "FDA May Proceed" },
  { value: "REVIEW", label: "FDA Under Review" },
  { value: "ISF HOLD", label: "ISF Hold" },
  { value: "PENDING SUBMISSION", label: "Pending Submission" },
];

export const shipmentDateOptions = [
  { value: "all", label: "All Dates" },
  { value: "next_week", label: "Next Week" },
  { value: "last_30_days", label: "Last 30 Days" },
  {
    value: "last_3_months",
    label: "Last 3 Months",
  },
  { value: "year_to_date", label: "Year to Date" },
];

export const actionOptions = [{ value: "delete", label: "Delete" }];

export const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    height: "32px",
    minHeight: "none",
    width: "auto",
    minWidth: "129px",
    border: "1px solid var(--border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};

export const statusSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    height: "32px",
    minHeight: "none",
    width: "auto",
    minWidth: "180px",
    border: "1px solid var(--border-color)",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};

export const formSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    height: "33px",
    minHeight: "none",
    width: "auto",
    minWidth: "129px",
    border: "1px solid var(--border-color)",
    fontSize: "14px",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontSize: "14px",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "black",
    opacity: ".5",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "black",
    maxHeight: "100%",
    svg: {
      color: "black",
      width: "16px!important",
    },
  }),
};

export const SuccessMessage = ({ style }) => {
  return (
    <>
      <div
        id="success-msg"
        style={{
          ...style,
          width: "fit-content",
        }}
      >
        <p>✓ Info succesfuly saved</p>
      </div>
    </>
  );
};

export const UnsavedChanges = () => {
  return (
    <div className="not-saved" style={{ display: "block!important" }}>
      Several Changes Not Saved
    </div>
  );
};

export const LoadingTable = () => {
  return <div className="loading-table"></div>;
};

export const validateEmail = (email) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) {
    return true;
  }
  return false;
};

export const objToUrl = (url, obj) => {
  const searchParams = new URLSearchParams();

  for (const key of Object.keys(obj)) {
    if (obj[key].length > 0 || typeof obj[key] === "number") {
      searchParams.append(key, obj[key]);
    }
  }

  let aux = `${url}?${searchParams}`;

  return aux;
};

export const fixedFormat = (dateStr) => {
  let date = new Date(dateStr);

  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1;
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return mm + "/" + dd + "/" + yyyy;
};
