import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Select from "react-select";

import Sidebar from "../../components/Sidebar";
import { Title } from "../../components/Title";
import {
  URL,
  token,
  customSelectStyles,
  LoadingTable,
  UnsavedChanges,
  role,
} from "../../components/Utils";
import { DeleteUser } from "./DeleteUser";

import "../../stylesheets/Admins.scss";
import { toast } from "react-toastify";

export const Users = () => {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [finalUrl, setFinalUrl] = useState(`${URL}/admin/manager_admins`);
  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    filter: "",
    order_by: "",
    field: "",
  });

  const gotoPreviousPage = () => {
    let aux = { ...extraUrl };
    if (page > 1) {
      setPage(page - 1);
      aux.page = page - 1;
      setExtraUrl(aux);
    }
  };

  const gotoNextPage = () => {
    let aux = { ...extraUrl };
    if (page < totalPages) {
      setPage(page + 1);
      aux.page = page + 1;
      setExtraUrl(aux);
    }
  };

  const [noRecords, setNoRecords] = useState(false);

  const handleClientFilter = (e) => {
    let aux = { ...extraUrl };
    if (e.value === "all_users") {
      aux.filter = "";
    } else {
      aux.filter = e.value;
    }
    setExtraUrl(aux);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let aux = { ...extraUrl };
    aux.search = e.target.value;

    setExtraUrl(aux);
  };

  const handleOrderBy = (ord) => {
    let aux = { ...extraUrl };
    let orders = ord.split(",");
    console.log(orders);
    aux.order_by = orders[0];
    aux.field = orders[1];
    setExtraUrl(aux);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0) {
        searchParams.append(key, extraUrl[key]);
      }
    }

    let aux = `${URL}/admin/manager_admins?${searchParams}`;
    setFinalUrl(decodeURIComponent(aux));
  }, [extraUrl]);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const resp = await fetch(finalUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token()}`,
          },
        });
        const data = await resp.json();
        if (resp.status === 200) {
          if (!data.hasOwnProperty("result")) {
            setNoRecords(true);
          } else {
            setNoRecords(false);
            setUsers(data.result.data);
            setTotalUsers(data.result.total);
            setTotalPages(data.result.last_page);
            setLoading(false);
          }
        } else {
          console.log("error");
        }
      } catch (e) {
        console.log(e);
      }
    };
    getUsers();
  }, [finalUrl]);

  const removeUser = (id) => {
    setUsers(users.filter((user) => user.id != id));
  };

  return (
    <>
      <div className="top">
        <Title
          title={`Users`}
          links={[
            { link: "/", title: "Home" },
            { link: "/users", title: "Users" },
          ]}
        />
      </div>
      <div id="home" className="page-content">
        <div id="users">
          <div className="data-table">
            <div className="header" style={{ marginBottom: "12px" }}>
              <div className="search-input">
                <input type="text" onChange={handleSearch} />
                <div className="icon">
                  <BsSearch />
                </div>
              </div>
              <div className="filters">
                <Select
                  styles={customSelectStyles}
                  defaultValue={"all_customers"}
                  onChange={handleClientFilter}
                  options={[
                    { label: "All Users", value: "all_users" },
                    { label: "Admins", value: "1" },
                    { label: "Managers", value: "2" },
                    { label: "Office Managers", value: "6" },
                  ]}
                  className="custom-select"
                  placeholder={"All Users"}
                />
                <NavLink to="/users/edit">
                  <button id="new-user-button">Add User</button>
                </NavLink>
              </div>
            </div>
            {noRecords ? (
              <>
                <h4>{`No users found`}</h4>
              </>
            ) : (
              <>
                <UsersTable
                  loading={loading}
                  users={users}
                  orderBy={handleOrderBy}
                  sortData={[extraUrl.order_by, extraUrl.field]}
                  removeUser={(id) => removeUser(id)}
                />
                {totalUsers <= 10 ? (
                  <></>
                ) : (
                  <div className="pagination">
                    <button
                      onClick={gotoPreviousPage}
                      style={page === 1 ? { opacity: "0" } : { opacity: "1" }}
                    >
                      {"< Previous"}
                    </button>
                    <span className="text">
                      {page * 10 < totalUsers ? page * 10 : totalUsers}/
                      {totalUsers}
                    </span>
                    <button
                      onClick={gotoNextPage}
                      style={
                        page === totalPages
                          ? { opacity: "0" }
                          : { opacity: "1" }
                      }
                    >
                      {"Next >"}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const UsersTable = ({ users, orderBy, sortData, removeUser, loading }) => {
  const activeRole = role();

  const handleRole = (role) => {
    const Circle = ({ color }) => {
      return (
        <span
          className="circle"
          style={{
            display: "block",
            width: "7.5px",
            height: "7.5px",
            borderRadius: "50%",
            backgroundColor: color,
          }}
        ></span>
      );
    };
    let formRole = "";
    let color = "red";
    switch (role) {
      case 0:
        formRole = "User";
        color = "red";
        break;
      case 1:
        formRole = "Admin";
        color = "#23B574";
        break;
      case 2:
        formRole = "Manager";
        color = "var(--atlantic-blue)";
        break;
      default:
        formRole = "Office Manager";
        color = "red";
        break;
    }
    return (
      <>
        <span>
          <Circle color={color} />
        </span>
        {formRole}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <div className="table">
          <div className="table-header">
            <div className="left">
              <div
                className="table-head username"
                onClick={() => {
                  orderBy(sortData.join(",") === "1,0" ? "0, 0" : "1,0");
                }}
              >
                <p>Username</p>
                <span className="arrow">
                  {sortData.join(",") === "1,0" ? (
                    <FaAngleUp />
                  ) : (
                    <FaAngleDown />
                  )}
                </span>
              </div>
              <div
                className="table-head role"
                onClick={() => {
                  orderBy(sortData.join(",") === "1,1" ? "0,1" : "1,1");
                }}
              >
                <p>Position</p>
                <span className="arrow">
                  {sortData.join(",") === "1,1" ? (
                    <FaAngleUp />
                  ) : (
                    <FaAngleDown />
                  )}
                </span>
              </div>
            </div>
            <div className="right">
              <div className="table-head user-actions">
                <p>Actions</p>
              </div>
            </div>
          </div>
          {!loading && typeof users !== "undefined" ? (
            users.map((user, i) => {
              return (
                <div className="table-body" key={user.id}>
                  <div className="left">
                    {activeRole == 1 || user.role == 2 ? (
                      <NavLink to={`/users/edit/${user.id}`}>
                        <div className="table-bod username">
                          <p>{user.name}</p>
                        </div>
                      </NavLink>
                    ) : (
                      <div className="table-bod username">
                        <p>{user.name}</p>
                      </div>
                    )}
                    <div className="table-bod role">
                      <p className="table-role">{handleRole(user.role)}</p>
                    </div>
                  </div>
                  <div className="right">
                    <div className="table-bod user-actions">
                      {activeRole == 1 || user.role == 2 ? (
                        <div className="actions">
                          <NavLink to={`/users/edit/${user.id}`}>
                            <BiEditAlt />
                          </NavLink>

                          <DeleteUser
                            id={user.id}
                            from={"table"}
                            removeUser={(id) => removeUser(id)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
