import '../stylesheets/ToggleSwitch.scss';
  
const ToggleSwitch = ({ label, value, setValue }) => {
  return (
    <div className="container toggle-switch-container">
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} checked={value ? 1 : 0} onChange={setValue} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
      <label htmlFor={label}><p>{label}{" "}</p></label>
    </div>
  );
};
  
export default ToggleSwitch;
