import "../../stylesheets/Login.scss";
import emailIcon from "../../assets/loginAssets/email-icon.svg";
import LoadingIcon from '../LoadingIcon';

import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import Cookies from "universal-cookie";

async function resetPass(credentials) {
  const URL = process.env.REACT_APP_API_URL;
  return fetch(
    URL + "/forgot-password",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Accept: 'application/json',
      body: JSON.stringify(credentials),
    }
  ).then((data) => data.json());
}
export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setUserEmail] = useState('');
  const [wrongStyle, setWrongStyle] = useState([]);
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const setErrorStyle = () => {
    setWrongStyle({
      border: "1px solid red",
    });
  };

  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validate = () => {
    if (email.length < 1 || !email.match(validRegex)) {
      setErrorMsg("Email address invalid");
      setErrorStyle();
      return false;
    }
    setWrongStyle({});
    return true;
  };

  //Where the form's data arrives and gets validated
  const handleSubmit = async (e) => {
    setErrorMsg("");
    setSuccess(false);
    setLoading(true);
    e.preventDefault();
    if (validate()) {
      const response = await resetPass({
        email
      });

      console.log(response);

      if(response?.errors) {
        setLoading(false);
        setErrorMsg(response.errors[0]);
        setUserEmail("");
      } else {
        setLoading(false);
        setSuccess(true);
        setUserEmail("");
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      <div id="forgot-pass">
        <img src={require("../../assets/atlantic-main-logo.png")} alt="" id="main-logo"/>
        <p className="login-title">ATLANTIC FREIGHT BROKERS</p>
        {errorMsg.length > 0 ? <p id="wrong-msg">{errorMsg}</p> : null}
        {success ? <p id="success-msg">Please check your email for instructions how to reset your password.</p> : null}
        <form className="inputs" onSubmit={handleSubmit}>
          <div
            style={
              typeof email === "undefined" || email.length <= 0 || !valid
                ? wrongStyle
                : {}
            }
            className="input-container"
          >
            <img src={emailIcon} alt="" />
            <input
              type="text"
              placeholder="Email Address"
              onChange={(e) => setUserEmail(e.target.value)}
              value={email}
            />
          </div>
          {loading ? <button className="button"><LoadingIcon /></button> : <input type="submit" value="Submit" className="button" />}
        </form>
        <a href="/login" className="forgot-pass">Login</a>
      </div>
    </>
  );
};
