import React, { useState, useEffect } from "react";
import { Title, LoadingTitle } from "../../components/Title";
import "../../stylesheets/HandleClients.scss";
import {
  URL,
  token,
  validateEmail,
  SuccessMessage,
  LoadingIcon,
  LoadingTable,
} from "../../components/Utils";

import { FaUser, FaEnvelope } from "react-icons/fa";

import { DeleteClient } from "./DeleteClient";

import { useParams, useNavigate } from "react-router-dom";
import { Subusers } from "./Subusers/Subusers";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions";

export const NewClient = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [client, setClient] = useState();
  const [emailError, setEmailError] = useState(false);
  const [accountError, setAccountError] = useState(false);

  const [newClientInfo, setNewClientInfo] = useState({
    account_no: "",
    email: "",
    name: "",
  });

  const [saved, setSaved] = useState({
    display: "none",
  });

  const uploadClient = async (e) => {
    setSaving(true);
    e.preventDefault();

    let obj = { ...newClientInfo };

    if (!id) {
      obj.role = "3";
    }

    const registerUrl = `${URL}/admin/register`;
    const updateUrl = `${URL}/admin/users/${id}/update`;

    loadingAnimation(true);

    try {
      const resp = await fetch(id ? updateUrl : registerUrl, {
        method: "POST",
        headers: new Headers({
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        }),
        body: JSON.stringify(obj),
      });

      if (resp.status == 200) {
        setSaving(false);
        setSaved({
          display: "block",
        });

        setTimeout(() => {
          setSaved({
            display: "none",
          });
        }, 2500);

        if (!id) {
          navigate(`/clients`);
        }
        loadingAnimation(false);
      } else {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      console.log(e);
      loadingAnimation(false);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  const getClientData = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/admin/users/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const data = await resp.json();

      if (resp.status == 200) {
        console.log(data);
        setClient(data);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (client) {
      let aux = { ...newClientInfo };

      aux.name = client.name;
      aux.email = client.email;
      aux.account_no = client.account_no;

      setNewClientInfo(aux);
    }
  }, [client]);

  useEffect(() => {
    if (id) {
      getClientData();
    }
  }, []);

  return (
    <>
      {(!client || loading) && id ? (
        <>
          <LoadingTitle />
          <div id="home" className="page-content">
            <LoadingTable />
          </div>
        </>
      ) : (
        <>
          <Title
            title={
              id ? `Edit ${newClientInfo.account_no}'s Info` : `New Client`
            }
          />
          <div id="home" className="page-content handle-users">
            <form id="handle-users" onSubmit={uploadClient}>
              <div className="section" className="title">
                <h3>User Information</h3>
              </div>
              <div className="section" id="account_no">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="sub-title">
                      Account No. (as entered in Editrade)
                    </p>
                  </div>
                  <div className="right">
                    {accountError && (
                      <p className="error-message">
                        Please enter a valid Account Number
                      </p>
                    )}
                    <div className={`input ${accountError ? "error" : ""}`}>
                      <div className="input-icon">
                        <FaUser />
                      </div>
                      <input
                        type="text"
                        id="account_no-input"
                        placeholder={"Account Number"}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.value.length < 1) {
                            setAccountError(true);
                          } else {
                            setAccountError(false);
                            setNewClientInfo({
                              ...newClientInfo,
                              account_no: e.target.value,
                            });
                          }
                        }}
                        defaultValue={id ? client.account_no : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" id="email">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="sub-title">Email Address</p>
                    <p className="light-text">
                      The user will get an invite sent to this email address
                      with login instructions
                    </p>
                  </div>
                  <div className="right">
                    {emailError && (
                      <p className="error-message">
                        Please enter a valid email
                      </p>
                    )}
                    <div className={`input ${emailError ? "error" : ""}`}>
                      <div className="input-icon">
                        <FaEnvelope />
                      </div>
                      <input
                        type="mail"
                        id="email-input"
                        placeholder={"Email Address"}
                        onChange={(e) => {
                          e.preventDefault();
                          if (!validateEmail(e.target.value)) {
                            setEmailError(true);
                          } else {
                            setEmailError(false);
                            setNewClientInfo({
                              ...newClientInfo,
                              email: e.target.value,
                            });
                          }
                        }}
                        defaultValue={id ? client.email : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" id="full_name">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="sub-title">Full Name</p>
                  </div>
                  <div className="right">
                    <div className="input">
                      <div className="input-icon">
                        <FaUser />
                      </div>
                      <input
                        type="text"
                        id="full_name-input"
                        placeholder={"Full Name"}
                        onChange={(e) => {
                          e.preventDefault();
                          setNewClientInfo({
                            ...newClientInfo,
                            name: e.target.value,
                          });
                        }}
                        defaultValue={id ? client.name : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SuccessMessage style={saved} />
              <div className="buttons">
                <button
                  id="save-btn"
                  type="submit"
                  style={
                    emailError ||
                    !validateEmail(newClientInfo.email) ||
                    accountError
                      ? {
                          opacity: ".5",
                          pointerEvents: "none",
                        }
                      : {}
                  }
                >
                  {saving ? <LoadingIcon /> : "Save"}
                </button>
                {id && <DeleteClient id={client.id} from={"individual"} />}
              </div>
            </form>
            <div id="subusers-table">
              <div className="section" className="title">
                <h3>Subusers</h3>
              </div>
              {client && (
                <Subusers
                  userId={id}
                  subusers={client.subusers.filter((item) => item.active)}
                  reload={() => getClientData()}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
