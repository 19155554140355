import { Title } from "./Title";
export const Error = () => {
  return (
    <>
      <Title title="Error :(" />
      <div id="error" className="page-content">
        <h2>ERROR <br /> THIS PAGE DOES NOT EXIST</h2>
      </div>
    </>
  );
}