import React, { useState } from "react";
import "./../../stylesheets/components/_uploader.scss";
import { token } from "../../components/Utils";
import { axios } from "axios";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions";

const Uploader = (props) => {
  const uploadFile = async (e) => {
    const URL = `${process.env.REACT_APP_API_URL}/admin/shipments/upload/${props?.shipmentId}`;

    if (e?.target?.files[0]) {
      const fileToUpload = e.target.files[0];
      loadingAnimation(true);

      const data = new FormData();
      data.append("file", fileToUpload);

      fetch(URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
        body: data,
      })
        .then((response) => response.json())
        .then((data) => {
          let uploadedItem = {
            created_at: null,
            deleted_at: null,
            document_type: "",
            path: data?.path_to_file,
            shipment_id: props?.shipmentId,
          };

          props?.uploadStatus("success", uploadedItem);
          toast.success("File uploaded!");
          loadingAnimation(false);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          props?.uploadStatus("fail");
          toast.error(
            "Something went wrong. Please, try again in afew minutes!"
          );
          loadingAnimation(false);
        });
    } else {
      console.log("No file has been selected");
      loadingAnimation(false);
    }
  };

  return (
    <div>
      <label className="custom-file-uploader">
        <input
          className="custom-file-uploader-input"
          type="file"
          accept=".xls,.xlsx,.csv,.png,.jpeg,.pdf"
          onChange={(e) => {
            uploadFile(e);
          }}
        />
        Select file
      </label>
    </div>
  );
};

export default Uploader;
