import React, { useEffect, useState } from "react";
import Select from "react-select";
import Cookies from "universal-cookie";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { BsSearch } from "react-icons/bs";
// import Swal from "sweetalert2";
import { confirmAlert } from "react-confirm-alert";
import { loadingAnimation } from "../../components/scripts/functions.js";

import { LoadingTitle, Title } from "../../components/Title.jsx";
import { getAllClients } from "../../components/Api";
import {
  URL,
  token,
  getDateRangeFilter,
  shipmentStatusOptions,
  shipmentDateOptions,
  customSelectStyles,
  fixedFormat,
  LoadingIcon,
} from "../../components/Utils";
import { toast } from "react-toastify";

import "../../stylesheets/Billing.scss";

import { BillingTable } from "../../components/table/billing/BillingTable.jsx";
import Loading from "../../components/Loading.jsx";

const cookies = new Cookies(null, { path: "/" });

export const Billing = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState();
  const [clients, setClients] = useState([]);
  const [maxPages, setMaxPages] = useState();
  const [page, setPage] = useState(1);
  const [totalInvoices, setTotalInvoices] = useState();
  const [userRole, setUserRole] = useState(null);
  const [deleteInvoiceIdList, setDeleteInvoiceIdList] = useState([]);
  const [resultIdList, setResultIdList] = useState([]);

  const { filter } = useParams();

  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    cust: "",
    date: "",
    ordering: "",
  });

  const [finalUrl, setFinalUrl] = useState(
    filter
      ? `${URL}/admin/invoices?page=${page}&status=${filter}`
      : `${URL}/admin/invoices?page=${page}`
  );

  const getUserRole = () => {
    const localRole = cookies.get("role");
    setUserRole(localRole);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0 || typeof extraUrl[key] === "number") {
        searchParams.append(key, extraUrl[key]);
      }
    }

    let aux = `${URL}/admin/invoices?${searchParams}`;
    setFinalUrl(aux);

    const onKeyDown = (e) => {
      if (e.key === "Delete") {
        deleteInvoices(e);
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [extraUrl, deleteInvoiceIdList]);

  const [invoicesInPage, setInvoicesInPage] = useState();
  const [perPage, setPerPage] = useState();

  const getInvoices = async () => {
    try {
      const response = await fetch(finalUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status == 200) {
        console.log(data);
        setInvoices(data.result.data);
        setInvoicesInPage(data.result.to);
        setPerPage(data.result.per_page);
        setMaxPages(data.result.last_page);
        setTotalInvoices(data.result.total);
        setLoading(false);
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getInvoices();
    getClients();
    getUserRole();
  }, [finalUrl, userRole]);

  const getClients = async () => {
    try {
      const response = await fetch(`${URL}/admin/all_clients?all=true`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status == 200) {
        if (data.result.length > 0) {
          setClients(data.result);
          console.log(data);
        }
      } else {
      }
    } catch (e) {
      console.log(e);
    }
  };

  /* Shipment pagination */
  const gotoPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setExtraUrl({ ...extraUrl, page: page - 1 });
    }
  };

  const gotoNextPage = () => {
    if (page < maxPages) {
      setPage(page + 1);
      setExtraUrl({ ...extraUrl, page: page + 1 });
    }
  };

  /* Shipment search */
  const clientsList = () => {
    let ret = [{ value: "all", label: "All Invoices" }];
    clients.map((client) => {
      ret.push({
        value: client.account_no.toLowerCase(),
        label: client.account_no,
      });
    });
    return ret;
  };

  const updateDeleteIds = (delete_ids) => {
    setDeleteInvoiceIdList(delete_ids);
  };

  const deleteInvoices = (e) => {
    if (deleteInvoiceIdList.length > 0) {
      confirmAlert({
        title: "Confirm to delete.",
        message: "Are you sure you want to delete invoice(s)?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              loadingAnimation(true);
              let delete_result = await deleteInvoicesRequest(
                deleteInvoiceIdList
              );

              if (delete_result?.status == "success") {
                setDeleteInvoiceIdList([]);
                setResultIdList([]);
                loadingAnimation(false);

                let resultArr = delete_result?.data?.deleted_invoices;

                resultArr.forEach((id) => {
                  const resultArr = [...resultIdList, id];
                  setResultIdList(resultArr);
                });

                getInvoices();
              } else {
                setDeleteInvoiceIdList([]);
                setResultIdList([]);
                loadingAnimation(false);

                let resultArr = resultIdList;

                setResultIdList([]);

                resultArr.forEach((id) => {
                  const resultArr = [...resultIdList, id];
                  setResultIdList(resultArr);
                });

                getInvoices();
              }
            },
          },
          {
            label: "No",
            onClick: () => {},
          },
        ],
        overlayClassName: "delete-client-overlay",
        closeOnEscape: false,
        closeOnClickOutside: false,
        keyCodeForClose: [8, 32],
      });
    } else {
      toast.info("Nothing to delete.");
    }
  };

  const deleteInvoicesRequest = async (id_array) => {
    let deleteUrl = `${URL}/admin/invoices/mass-delete`;
    let body = {
      invoiceIds: id_array,
    };
    let result = await fetch(deleteUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return {
          status: "success",
          data: data,
        };
      })
      .catch((error) => {
        toast.error(
          `Something went wrong. Please, try again in a few minutes!`
        );
        return {
          status: "failed",
          data: error,
        };
      });

    return result;
  };

  // const loadingAnimation = (state) => {
  //   if (state === true) {
  //     Swal.fire({
  //       customClass: {
  //         container:
  //           "custom-loading-animation-swal-container delete-client-overlay",
  //         popup: "custom-loading-animation-swal-popup",
  //       },
  //       showCancelButton: false,
  //       showConfirmButton: false,
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  //   } else {
  //     Swal.close();
  //   }
  // };

  return (
    <>
      <Title
        title={`Billing`}
        links={[
          { link: "/", title: "Home" },
          { link: "/billing", title: "Billing" },
        ]}
      />
      <div id="billing" className="page-content">
        <div className="data-table">
          <div className="header" style={{ marginBottom: "12px" }}>
            <div className="filters">
              <div className="search-input">
                <input
                  type="text"
                  className="search-shipments"
                  onChange={(e) => {
                    e.preventDefault();
                    setExtraUrl({ ...extraUrl, search: e.target.value });
                  }}
                />
                <div className="icon">
                  <BsSearch />
                </div>
              </div>

              {clients ? (
                <Select
                  styles={customSelectStyles}
                  defaultValue={"all"}
                  onChange={(e) => {
                    let value = e.value;
                    value !== "all"
                      ? setExtraUrl({ ...extraUrl, cust: e.value })
                      : setExtraUrl({ ...extraUrl, cust: "" });
                  }}
                  options={clientsList()}
                  className="custom-select"
                  placeholder={"All Clients"}
                />
              ) : null}

              {userRole == 1 ? (
                <button
                  style={{
                    display: deleteInvoiceIdList?.length > 0 ? "block" : "none",
                  }}
                  className={"red-bg white"}
                  onClick={(e) => deleteInvoices(e)}
                  data-enabled={deleteInvoiceIdList?.length > 0 ? true : false}
                >
                  Delete
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="table">
            {invoices && (
              <BillingTable
                invoices={invoices}
                deleteInvoicesIds={updateDeleteIds}
                resultDeleted={resultIdList}
              />
            )}
          </div>
          <div className="pagination">
            {page != 1 && (
              <button onClick={gotoPreviousPage}>{"< Previous"}</button>
            )}
            <span className="text">
              {page * perPage < totalInvoices ? page * perPage : totalInvoices}/
              {totalInvoices}
            </span>
            {page != maxPages && (
              <button onClick={gotoNextPage}>{"Next >"}</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const BillingInvoice = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  if (!token()) {
    navigate("/login");
  }

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Delete") {
        deleteInvoice();
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  const URL = process.env.REACT_APP_API_URL + "/admin";

  const [invoice, setInvoice] = useState();
  const [deleting, setDeleting] = useState(false);

  const getInvoices = async () => {
    try {
      const response = await fetch(`${URL}/invoices/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      let data = await response.json();
      if (response.status == 200) {
        if (data.invoice) {
          setInvoice(data.invoice);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUrl = (path) => {
    let formattedURL = `${process.env.REACT_APP_BASE_URL}/${path}`;
    return formattedURL;
  };

  const [uploadedBackup, setUploadedBackup] = useState(null);
  const [uploadedBackupName, setUploadedBackupName] = useState(null);
  const [loadingBackupUpload, setLoadingBackupUpload] = useState(false);
  const handleBackupUpload = async (e) => {
    setUploadedBackup(e.target.files[0]);
    setUploadedBackupName(e.target.files[0].name);
    setLoadingBackupUpload(true);
    const endpoint = `${URL}/invoices/${id}/backup`;

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    loadingAnimation(true);
    fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message == "Upload Successful!") {
          setLoadingBackupUpload(false);
          setInvoice({ ...invoice, backup: data.path_to_file });
          console.log("File uploaded successfully!");
          loadingAnimation(false);
        } else {
          // throw new Error("Error uploading file.");
          loadingAnimation(false);
          toast.error(
            "Something went wrong. Please, try again in afew minutes!"
          );
        }
      })
      .catch((error) => {
        console.error("Upload error:", error);
        loadingAnimation(false);
        toast.error("Something went wrong. Please, try again in afew minutes!");
      });
  };

  const getFileName = (path) => {
    return path.split("/")[path.split("/").length - 1];
  };

  const deleteBackup = async () => {
    const endpoint = `${URL}/invoices/${id}/backup`;

    const formData = new FormData();
    loadingAnimation(true);
    fetch(endpoint, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setLoadingBackupUpload(false);
          setInvoice({ ...invoice, backup: "" });
          loadingAnimation(false);
        } else {
          // throw new Error("Error deleting the file.");
          loadingAnimation(false);
          toast.error(
            "Something went wrong. Please, try again in afew minutes!"
          );
        }
      })
      .catch((error) => {
        console.error("Upload error:", error);
        toast.error("Something went wrong. Please, try again in afew minutes!");
      });
  };

  const deleteInvoice = async () => {
    const endpoint = `${URL}/invoices/${id}`;

    confirmAlert({
      title: "Confirm to delete.",
      message: "Are you sure you want to delete invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            loadingAnimation(true);
            const formData = new FormData();
            fetch(endpoint, {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token()}`,
              },
            })
              .then((response) => {
                if (response.ok) {
                  setLoadingBackupUpload(false);
                  loadingAnimation(false);
                  navigate("/billing");
                } else {
                  // throw new Error("Error deleting the invoice.");
                  loadingAnimation(false);
                  toast.error(
                    "Something went wrong. Please, try again in afew minutes!"
                  );
                }
              })
              .catch((error) => {
                // console.error("Upload error:", error);
                loadingAnimation(false);
                toast.error(
                  "Something went wrong. Please, try again in afew minutes!"
                );
              });
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      overlayClassName: "delete-client-overlay",
      closeOnEscape: false,
      closeOnClickOutside: false,
      keyCodeForClose: [8, 32],
    });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  function truncate(str, n) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  return (
    <>
      {invoice ? (
        <Title
          title={`Invoice ${
            invoice.shipment != null ? invoice.shipment.file_number : ""
          }`}
          links={[
            { link: "/", title: "Home" },
            { link: "/billing", title: "Billing" },
            {
              link: `/billing/${invoice.id}`,
              title: `Invoice ${
                invoice.shipment != null ? invoice.shipment.file_number : ""
              }`,
            },
          ]}
        />
      ) : (
        <LoadingTitle />
      )}
      <div id="invoice" className="page-content">
        <div className="left-side">
          {invoice && (
            <embed
              type="application/pdf"
              src={getUrl(invoice.path)}
              width="100%"
              height="100%"
            />
          )}
        </div>
        <div className="right-side">
          <section id="section-2" className="pay-info">
            <p className="title">ACH/Wire</p>
            <div className="pay-links">
              <p id="right">
                Account: 472011571 <br />
                Routing: 021000021
              </p>
              <p id="left">
                Swift Code: CHASUS33 <br />
                Bank: Chase
              </p>
            </div>
          </section>
          <section id="section-3" className="pay-info">
            <p className="title">Quickpay/Zelle</p>
            <div className="pay-links">
              <p id="right">
                Recipient: <span className="email">hillel@atlanticfb.com</span>
              </p>
            </div>
          </section>
          <section id="section-4" className="pay-info">
            <p className="title">Email Check Image</p>
            <div className="pay-links">
              <p id="right">
                Send to:
                <br />
                <a href="mailto:hillel@atlanticfb.com" className="email">
                  hillel@atlanticfb.com
                </a>
                <br />
                <a href="mailto:mimi@atlanticfb.com" className="email">
                  mimi@atlanticfb.com
                </a>
                <br />
                <a href="mailto:abraham@atlanticfb.com" className="email">
                  abraham@atlanticfb.com
                </a>
              </p>
              <a
                className="email"
                href="mailto:abraham@atlanticfb.com; mailto:mimi@atlanticfb.com; mailto:abraham@atlanticfb.com"
              >
                Send to all
              </a>
            </div>
          </section>
          <section id="section-5" className="pay-info">
            <p className="title">Mail Check</p>
            <div className="pay-links">
              <p id="right">
                Atlantic Freight Brokers <br />
                453 Broadway STE 111. Brooklyn NY 11211
              </p>
            </div>
          </section>

          <form className="upload-backup-field">
            <input
              type="file"
              name="backup"
              id="backup-input"
              onChange={handleBackupUpload}
            />

            <label htmlFor="backup" className="flex jc-c ai-c">
              {loadingBackupUpload ? (
                <LoadingIcon />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="20"
                    viewBox="0 0 15 20"
                  >
                    <path
                      id="Icon_awesome-file-upload"
                      data-name="Icon awesome-file-upload"
                      d="M8.75,5.313V0H.938A.935.935,0,0,0,0,.938V19.063A.935.935,0,0,0,.938,20H14.063A.935.935,0,0,0,15,19.063V6.25H9.688A.94.94,0,0,1,8.75,5.313ZM11.3,13.75H8.75v3.125a.625.625,0,0,1-.625.625H6.875a.625.625,0,0,1-.625-.625V13.75H3.7a.625.625,0,0,1-.44-1.069L7.03,8.943a.666.666,0,0,1,.939,0l3.766,3.738A.625.625,0,0,1,11.3,13.75ZM14.727,4.1,10.9.273A.937.937,0,0,0,10.238,0H10V5h5V4.762A.935.935,0,0,0,14.727,4.1Z"
                      fill="#23b574"
                    />
                  </svg>
                  <span className="text">
                    {uploadedBackupName == null
                      ? "Upload Backup"
                      : uploadedBackupName}
                  </span>
                </>
              )}
            </label>
          </form>
          {invoice && invoice.backup.length > 0 && (
            <a
              className="name"
              href={`${process.env.REACT_APP_BASE_URL}/storage/${invoice.backup}`}
              download="backup"
              target="_blank"
            >
              <div
                className={`file-visualizer flex jc-c ai-c column available`}
              >
                <div
                  id="close-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteBackup();
                  }}
                >
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <g id="Menu / Close_SM">
                        <path
                          id="Vector"
                          d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                          stroke="#6e6e6e"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="40"
                  viewBox="0 0 30 40"
                >
                  <path
                    id="file-arrow-down-solid"
                    d="M30,10H20V0ZM20,12.5H30V36.25A3.75,3.75,0,0,1,26.25,40H3.75A3.75,3.75,0,0,1,0,36.25V3.75A3.75,3.75,0,0,1,3.75,0H17.5V10A2.5,2.5,0,0,0,20,12.5Zm-.078,10.547L16.875,26.1V18.125a1.875,1.875,0,0,0-3.75,0V26.1l-3.117-3.055A1.6,1.6,0,0,0,8.75,22.5a1.855,1.855,0,0,0-1.326.547,1.875,1.875,0,0,0,0,2.652l6.25,6.25a1.875,1.875,0,0,0,2.652,0l6.25-6.25a1.876,1.876,0,1,0-2.654-2.652Z"
                    fill="#23b574"
                  />
                </svg>

                {truncate(getFileName(invoice.backup), 10)}
              </div>
            </a>
          )}

          <button class="danger button" onClick={deleteInvoice}>
            Delete
          </button>
        </div>
      </div>
    </>
  );
};
