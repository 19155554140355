import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useSearchParams } from "react-router-dom";
import { Title, LoadingTitle } from "../../components/Title.jsx";
import "../../stylesheets/Shipments.scss";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import Swal from "sweetalert2";
import { loadingAnimation } from "../../components/scripts/functions.js";

import {
  ShipmentsTable,
  LoadingTable,
} from "../../components/table/shipments/ShipmentTable.jsx";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { BsSearch } from "react-icons/bs";
import {
  URL,
  token,
  getDateRangeFilter,
  shipmentStatusOptions,
  shipmentDateOptions,
  statusSelectStyles,
  customSelectStyles,
  actionOptions,
} from "../../components/Utils";
import { toast } from "react-toastify";

import Select from "react-select";

const cookies = new Cookies(null, { path: "/" });

const Shipments = () => {
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState();

  const [selectShipmentsToggle, setSelectShipmentsToggle] = useState(false);
  const [deletedShipmentsResult, setDeletedShipmentsResult] = useState([]);

  const [shipmentIds, setShipmentIds] = useState([]);
  const [clients, setClients] = useState([]);
  const [maxPages, setMaxPages] = useState();
  const [page, setPage] = useState(1);
  const [totalShipments, setTotalShipments] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [userRole, setUserRole] = useState(null);
  const [emptIds, setEmptIds] = useState(false);

  const [testSIds, setTestSIds] = useState([]);

  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    home: searchParams.get("home") ? searchParams.get("home") : 0,
    cust: "",
    status: searchParams.get("status") ? searchParams.get("status") : "",
    date: "",
    ordering: "",
    custom: searchParams.get("custom") ? searchParams.get("custom") : "",
  });
  const handleShipmentIdChange = (idArray) => {
    setShipmentIds(idArray);
  };
  const generateUrl = () => {
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0 || typeof extraUrl[key] === "number") {
        searchParams.append(key, extraUrl[key]);
      }
    }

    let aux = `${URL}/admin/shipments?${searchParams}`;

    return aux;
  };

  const [finalUrl, setFinalUrl] = useState(generateUrl());

  useEffect(() => {
    const searchParams = new URLSearchParams();

    let searchVar = "";

    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0 || typeof extraUrl[key] === "number") {
        if (key == "search" && extraUrl[key].length > 0) {
          searchVar = extraUrl[key];
        }

        searchParams.append(key, extraUrl[key]);
      }
    }

    if (searchVar) {
      searchParams.set("page", 1);
    }

    let aux = `${URL}/admin/shipments?${searchParams}`;

    setFinalUrl(aux);

    const onKeyDown = (e) => {
      if (e.key === "Delete") {
        deleteShipments(e);
      }
    };

    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [extraUrl, shipmentIds]);

  const [shipmentsInPage, setShipmentsInPage] = useState();
  const [perPage, setPerPage] = useState();

  const getShipments = async () => {
    try {
      const response = await fetch(finalUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status == 200) {
        setShipments(data.result.data);
        setShipmentsInPage(data.result.to);
        setPerPage(data.result.per_page);
        setMaxPages(data.result.last_page);
        setTotalShipments(data.result.total);
        setLoading(false);
        loadingAnimation(false);
      }
    } catch (e) {
      loadingAnimation(false);
    }
  };

  const getUserRole = () => {
    const localRole = cookies.get("role");
    setUserRole(localRole);
  };

  useEffect(() => {
    getShipments();
    getClients();
    getUserRole();
  }, [finalUrl, userRole]);

  const getClients = async () => {
    try {
      const response = await fetch(`${URL}/admin/all_clients?all=true`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status == 200) {
        if (data.result.length > 0) {
          setClients(data.result);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  /* Shipment pagination */
  const gotoPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setExtraUrl({ ...extraUrl, page: page - 1 });
    }
  };

  const gotoNextPage = () => {
    if (page < maxPages) {
      setPage(page + 1);
      setExtraUrl({ ...extraUrl, page: page + 1 });
    }
  };

  /* Shipment search */
  const clientsList = () => {
    let ret = [{ value: "all", label: "All Shipments" }];
    clients.map((client) => {
      ret.push({
        value: client.account_no.toLowerCase(),
        label: client.account_no,
      });
    });
    return ret;
  };

  // Shipment Deletion Function
  const deleteShipments = async (e) => {
    let enabled = e.target.dataset.enabled;

    if (enabled && enabled == "true") {
      confirmAlert({
        title: "Confirm to delete.",
        message: "Are you sure you want to delete shipment(s)?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              let deleteUrl = `${URL}/admin/shipments`;
              let body = {
                ids: shipmentIds,
              };
              loadingAnimation(true);
              let res = await fetch(deleteUrl, {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token()}`,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
              })
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  setDeletedShipmentsResult([]);
                  setSelectShipmentsToggle(false);
                  let deleted = data?.details[0]
                    ?.split("ID(s):")[1]
                    ?.split(",");

                  deleted.forEach((id) => {
                    const resultArr = [...deletedShipmentsResult, id];
                    setDeletedShipmentsResult(resultArr);
                  });
                  setShipmentIds([]);

                  toast.success("Deleted!");

                  return true;
                })
                .catch((error) => {
                  toast.error(
                    "Could not delete. Please, try again in a few minutes!"
                  );
                  setSelectShipmentsToggle(false);

                  return true;
                });

              if (res) {
                getShipments();
              }
            },
          },
          {
            label: "No",
          },
        ],
        overlayClassName: "delete-client-overlay",
        closeOnEscape: false,
        closeOnClickOutside: false,
        keyCodeForClose: [8, 32],
      });
    } else {
      if (e.key === "Delete" || e.key === "Backspace") {
        if (shipmentIds.length > 0) {
          confirmAlert({
            title: "Confirm to delete.",
            message: "Are you sure you want to delete shipment(s)?",
            buttons: [
              {
                label: "Yes",
                onClick: async () => {
                  let deleteUrl = `${URL}/admin/shipments`;
                  let body = {
                    ids: shipmentIds,
                  };
                  loadingAnimation(true);
                  let res = await fetch(deleteUrl, {
                    method: "DELETE",
                    headers: {
                      Authorization: `Bearer ${token()}`,
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(body),
                  })
                    .then((response) => {
                      return response.json();
                    })
                    .then((data) => {
                      setDeletedShipmentsResult([]);
                      setSelectShipmentsToggle(false);
                      let deleted = data?.details[0]
                        ?.split("ID(s):")[1]
                        ?.split(",");
                      deleted.forEach((id) => {
                        const resultArr = [...deletedShipmentsResult, id];
                        setDeletedShipmentsResult(resultArr);
                      });
                      setShipmentIds([]);
                      toast.success("Deleted!");
                      return true;
                    })
                    .catch((error) => {
                      toast.error(
                        "Could not delete. Please, try again in a few minutes!"
                      );
                      setSelectShipmentsToggle(false);
                      return true;
                    });
                  if (res) {
                    getShipments();
                  }
                },
              },
              {
                label: "No",
              },
            ],
            overlayClassName: "delete-client-overlay",
            closeOnEscape: false,
            closeOnClickOutside: false,
            keyCodeForClose: [8, 32],
          });
        } else {
          toast.info("Nothing to delete");
        }
      }
    }
  };

  const toggleSelectAllShipments = () => {
    if (selectShipmentsToggle === false) {
      setSelectShipmentsToggle(true);
    } else if (selectShipmentsToggle === true) {
      setSelectShipmentsToggle(false);
    }
  };

  return (
    <>
      {loading || typeof shipments === "undefined" ? (
        <>
          <LoadingTitle />
          <div id="home" className="page-content">
            <div className="data-table">
              <div className="header" style={{ marginBottom: "12px" }}>
                <div className="filters">
                  <div className="search-input">
                    <input
                      type="text"
                      className="search-shipments"
                      onChange={(e) => {
                        e.preventDefault();
                        setExtraUrl({
                          ...extraUrl,
                          search: e.target.value,
                        });
                      }}
                    />
                    <div className="icon">
                      <BsSearch />
                    </div>
                  </div>

                  <Select
                    styles={statusSelectStyles}
                    defaultValue={
                      searchParams.get("status")
                        ? searchParams.get("status")
                        : "all"
                    }
                    onChange={(e) => {
                      let value = e.value;
                      if (value === "all") {
                        setExtraUrl({ ...extraUrl, status: "" });
                      } else {
                        setExtraUrl({ ...extraUrl, status: value });
                      }
                    }}
                    options={shipmentStatusOptions}
                    className="custom-select"
                    placeholder={"All Shipments"}
                  />

                  {clients ? (
                    <Select
                      styles={customSelectStyles}
                      defaultValue={"all"}
                      onChange={(e) => {
                        let value = e.value;
                        value !== "all"
                          ? setExtraUrl({ ...extraUrl, cust: e.value })
                          : setExtraUrl({ ...extraUrl, cust: "" });
                      }}
                      options={clientsList()}
                      className="custom-select"
                      placeholder={"All Clients"}
                    />
                  ) : null}

                  <Select
                    styles={customSelectStyles}
                    defaultValue={"all"}
                    onChange={(e) => {
                      e.value === "all"
                        ? setExtraUrl({
                          ...extraUrl,
                          date: getDateRangeFilter("all"),
                        })
                        : setExtraUrl({
                          ...extraUrl,
                          date: getDateRangeFilter(e.value),
                        });
                    }}
                    options={shipmentDateOptions}
                    className="custom-select"
                    placeholder={"All Dates"}
                  />
                </div>
              </div>

              <LoadingTable />
            </div>
          </div>
        </>
      ) : (
        <>
          <Title
            title={`Shipments`}
            links={[
              { link: "/", title: "Home" },
              { link: "/shipments", title: "Shipments" },
            ]}
          />
          <div id="home" className="page-content">
            <div className="data-table">
              <div className="header" style={{ marginBottom: "12px" }}>
                <div className="filters">
                  <div className="search-input">
                    <input
                      type="text"
                      className="search-shipments"
                      onChange={(e) => {
                        e.preventDefault();
                        setExtraUrl({
                          ...extraUrl,
                          search: e.target.value,
                        });
                      }}
                    />
                    <div className="icon">
                      <BsSearch />
                    </div>
                  </div>

                  <Select
                    styles={statusSelectStyles}
                    defaultValue={
                      searchParams.get("status")
                        ? searchParams.get("status")
                        : "all"
                    }
                    onChange={(e) => {
                      let value = e.value;
                      if (value === "all") {
                        setExtraUrl({ ...extraUrl, status: "" });
                      } else {
                        setExtraUrl({ ...extraUrl, status: value });
                      }
                    }}
                    options={shipmentStatusOptions}
                    className="custom-select"
                    placeholder={"All Shipments"}
                  />

                  {clients ? (
                    <Select
                      styles={customSelectStyles}
                      defaultValue={"all"}
                      onChange={(e) => {
                        let value = e.value;
                        value !== "all"
                          ? setExtraUrl({ ...extraUrl, cust: e.value })
                          : setExtraUrl({ ...extraUrl, cust: "" });
                      }}
                      options={clientsList()}
                      className="custom-select"
                      placeholder={"All Clients"}
                    />
                  ) : null}

                  <Select
                    styles={customSelectStyles}
                    defaultValue={"all"}
                    onChange={(e) => {
                      e.value === "all"
                        ? setExtraUrl({
                          ...extraUrl,
                          date: getDateRangeFilter("all"),
                        })
                        : setExtraUrl({
                          ...extraUrl,
                          date: getDateRangeFilter(e.value),
                        });
                    }}
                    options={shipmentDateOptions}
                    className="custom-select"
                    placeholder={"All Dates"}
                  />
                  {userRole == 1 ? (
                    <button
                      style={{
                        display: shipmentIds.length > 0 ? "block" : "none",
                      }}
                      className={"red-bg white"}
                      onClick={(e) => deleteShipments(e)}
                      data-enabled={shipmentIds.length > 0 ? true : false}
                    >
                      Delete
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="table-parts">
                <table className="shipments">
                  <thead className="head">
                    <tr>
                      {userRole == 1 ? (
                        <th className="select_shipment">
                          <div
                            className="checkbox"
                            onClick={() => toggleSelectAllShipments()}
                          >
                            {selectShipmentsToggle == true ? (
                              <p className="checked-item">✓</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </th>
                      ) : (
                        <></>
                      )}
                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,0") {
                            setExtraUrl({ ...extraUrl, ordering: "0,0" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,0" });
                          }
                        }}
                      >
                        Entry Number
                        {extraUrl.ordering == "1,0" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>

                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,7") {
                            setExtraUrl({ ...extraUrl, ordering: "0,7" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,7" });
                          }
                        }}
                      >
                        Master Bill of Lading
                        {extraUrl.ordering == "1,7" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>
                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,8") {
                            setExtraUrl({ ...extraUrl, ordering: "0,8" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,8" });
                          }
                        }}
                      >
                        House Bill of Lading
                        {extraUrl.ordering == "1,8" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>

                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,1") {
                            setExtraUrl({ ...extraUrl, ordering: "0,1" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,1" });
                          }
                        }}
                      >
                        Container Number(s)
                        {extraUrl.ordering == "1,1" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>

                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,2") {
                            setExtraUrl({ ...extraUrl, ordering: "0,2" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,2" });
                          }
                        }}
                      >
                        Reference Number
                        {extraUrl.ordering == "1,2" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>

                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,4") {
                            setExtraUrl({ ...extraUrl, ordering: "0,4" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,4" });
                          }
                        }}
                      >
                        ETA
                        {extraUrl.ordering == "1,4" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>

                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,3") {
                            setExtraUrl({ ...extraUrl, ordering: "0,3" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,3" });
                          }
                        }}
                      >
                        Client
                        {extraUrl.ordering == "1,3" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>
                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,6") {
                            setExtraUrl({ ...extraUrl, ordering: "0,6" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,6" });
                          }
                        }}
                      >
                        FDA
                        {extraUrl.ordering == "1,6" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>
                      <th
                        onClick={() => {
                          setExtraUrl({ ...extraUrl, page: 1 });
                          if (extraUrl.ordering == "1,5") {
                            setExtraUrl({ ...extraUrl, ordering: "0,5" });
                          } else {
                            setExtraUrl({ ...extraUrl, ordering: "1,5" });
                          }
                        }}
                      >
                        Status
                        {extraUrl.ordering == "1,5" ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </th>
                    </tr>
                  </thead>
                  <ShipmentsTable
                    shipments={shipments}
                    onIdArrayChange={handleShipmentIdChange}
                    toggleSelectAll={selectShipmentsToggle}
                    deletedShipments={deletedShipmentsResult}
                  />
                </table>
                <div className="pagination">
                  {page != 1 && (
                    <button onClick={gotoPreviousPage}>{"< Previous"}</button>
                  )}
                  <span className="text">
                    {page == maxPages
                      ? `${totalShipments}/${totalShipments}`
                      : `${page * perPage}/${totalShipments}`}
                  </span>
                  {page != maxPages && (
                    <button onClick={gotoNextPage}>{"Next >"}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Shipments;
