import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Select from "react-select";

import { Title } from "../../../components/Title";
import {
  URL,
  token,
  customSelectStyles,
  LoadingTable,
} from "../../../components/Utils";
import { DeleteUser } from "./DeleteUser";

import "../../../stylesheets/Admins.scss";
import "../../../stylesheets/Users.scss";

import { toast } from "react-toastify";

export const Subusers = ({ userId, subusers, reload }) => {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(subusers);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    console.log(searchResults);

    const filteredUsers = subusers.filter((item) =>
      item.name.toLowerCase().includes(value)
    );

    setSearchResults(filteredUsers);
  };

  const handleFilter = (e) => {
    const value = e.value;
    let filteredUsers = subusers;
    if (value) {
      filteredUsers = subusers.filter((item) => item.role == value);
    }

    setSearchResults(filteredUsers);
  };

  return (
    <>
      <div
        id="users"
        style={{
          marginTop: "16px",
        }}
      >
        <div className="data-table">
          <div className="header flex" style={{ marginBottom: "12px" }}>
            <div className="search-input">
              <input type="text" defaultValue={""} onChange={handleSearch} />
              <div className="icon">
                <BsSearch />
              </div>
            </div>
            <div className="filters flex">
              <Select
                styles={customSelectStyles}
                defaultValue={"all_customers"}
                // value={filter}
                onChange={handleFilter}
                options={[
                  { label: "All User", value: 0 },
                  { label: "User", value: "5" },
                  { label: "Managers", value: "4" },
                ]}
                className="custom-select"
                placeholder={"All Users"}
              />
              <NavLink to={`/clients/edit-client/${userId}/subuser/new-user`}>
                <button id="new-user-button">Add User</button>
              </NavLink>
            </div>
          </div>
          <>
            <UsersTable
              loading={loading}
              subusers={searchResults}
              userId={userId}
              reload={reload}
            />
          </>
        </div>
      </div>
    </>
  );
};

const UsersTable = ({ subusers, loading, userId, reload }) => {
  const handleRole = (role) => {
    const Circle = ({ color }) => {
      return (
        <span
          className="circle"
          style={{
            display: "block",
            width: "7.5px",
            height: "7.5px",
            borderRadius: "50%",
            backgroundColor: color,
          }}
        ></span>
      );
    };
    let formRole = "";
    let color = "red";
    switch (role) {
      case 5:
        formRole = "User";
        color = "var(--green)";
        break;
      case 4:
        formRole = "Manager";
        color = "var(--green)";
        break;
      default:
        formRole = "Not defined";
        color = "red";
        break;
    }
    return (
      <>
        <span>
          <Circle color={color} />
        </span>
        {formRole}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <div className="table">
          <div className="table-header">
            <div className="left">
              <div className="table-head username">
                <p>Username</p>
                <span className="arrow">
                  <FaAngleDown />
                </span>
              </div>
              <div className="table-head role">
                <p>Position</p>
                <span className="arrow">
                  <FaAngleDown />
                </span>
              </div>
            </div>
            <div className="right">
              <div className="table-head user-actions">
                <p>Actions</p>
              </div>
            </div>
          </div>
          {!loading && typeof subusers !== "undefined" ? (
            subusers.map((user, i) => {
              return (
                <div className="table-body" key={user.id}>
                  <div className="left">
                    <NavLink
                      to={`/clients/edit-client/${userId}/subuser/${user.id}`}
                    >
                      <div className="table-bod username">
                        <p>{user.name}</p>
                      </div>
                    </NavLink>
                    <div className="table-bod role">
                      <p className="table-role">{handleRole(user.role)}</p>
                    </div>
                  </div>
                  <div className="right">
                    <div className="table-bod user-actions">
                      <div className="actions">
                        <NavLink
                          to={`/clients/edit-client/${userId}/subuser/${user.id}`}
                        >
                          <BiEditAlt />
                        </NavLink>

                        <DeleteUser
                          id={user.id}
                          from={"table"}
                          client={userId}
                          reload={reload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
