import { NavLink } from "react-router-dom";
import '../../stylesheets/Box.scss';

export function LoadingBox(props) {
  return (
    <div className="box loading-box">
      <div className="box-numb">
        <p>
          4
        </p>
      </div>
      <div className="box-text">
        <p>
          shipments needing uploads
        </p>
      </div>
    </div>
  );
}

export function Box(props) {
  return (
    <div className="box box-home">
      <div className="box-numb">
        <p>
          {props.number}
        </p>
      </div>
      <div className="box-text">
        <p>
          {props.text}
        </p>
        <p>
          {props.text2}
        </p>
      </div>
    </div>
  );
}