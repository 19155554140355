import React, { useEffect, useMemo, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTable, useSortBy } from "react-table";
import { format } from "date-fns";
import Cookies from "universal-cookie";

import Loading from "../../../components/Loading";

import "../../../stylesheets/tables.scss";

const cookies = new Cookies(null, { path: "/" });

//Function that recieves the number of extra containers
const fewContainers = (containers) => {
  var formatted = "";
  for (let i = 0; i < containers.length; i++) {
    formatted += containers[i] + ", ";
  }

  return <span>{formatted.slice(0, formatted.length - 2)}</span>;
};

const ManyContainers = ({ containers }) => {
  var firstShipments = "";
  var otherShipments = "";
  var count = 0;
  var [opened, setOpened] = useState(false);

  if (!(typeof containers === "undefined" || containers.length <= 0)) {
    for (let i = 0; i <= 1; i++) {
      //First three shipments
      firstShipments += containers[i] + ", ";
    }
    for (let i = 2; i < containers.length; i++) {
      otherShipments += containers[i] + ", ";
      count += 1;
    }
  }

  //Removing last ","
  return (
    <span onClick={() => setOpened(!opened)} style={{ cursor: "pointer" }}>
      {firstShipments.slice(0, firstShipments.length - 2)},
      {opened ? otherShipments.slice(0, otherShipments.length - 2) : ""}
      {!opened ? (
        <span className="container-extensor">+{count}</span>
      ) : (
        <span className="container-extensor" style={{ marginRight: "-1rem" }}>
          close
        </span>
      )}
    </span>
  );
};

export const BillingTable = ({
  invoices,
  deleteInvoicesIds,
  resultDeleted,
}) => {
  const data = useMemo(() => invoices, [invoices]);
  const [selectInvoicesToggle, setSelectInvoicesToggle] = useState(false);
  const [invoiceIdList, setInvoiceIdList] = useState([]);
  const invoiceTableRef = useRef(null);
  const [userRole, setUserRole] = useState(null);

  const toggleSelectAllInvoices = () => {
    const rowList =
      invoiceTableRef?.current?.querySelectorAll(".invoice-table-row");

    if (selectInvoicesToggle === false) {
      setSelectInvoicesToggle(true);

      rowList?.forEach((item) => {
        let found = invoiceIdList?.find((it) => it == item?.dataset?.invoiceId);

        if (!found) {
          setInvoiceIdList((oldArray) => [
            ...oldArray,
            parseInt(item?.dataset?.invoiceId),
          ]);
        }
      });
    } else if (selectInvoicesToggle === true) {
      setSelectInvoicesToggle(false);
      setInvoiceIdList([]);
    }
  };
  const getUserRole = () => {
    const localRole = cookies.get("role");
    setUserRole(localRole);
  };

  const selectInvoice = (id, event) => {
    if (event.target.dataset.checked == "false") {
      setInvoiceIdList((oldArray) => [...oldArray, parseInt(id)]);
    } else if (event.target.dataset.checked == "true") {
      setInvoiceIdList(invoiceIdList.filter((item) => item != id));
    }
  };

  useEffect(() => {
    deleteInvoicesIds(invoiceIdList);

    if (invoiceIdList?.length <= 0) {
      setSelectInvoicesToggle(false);
    }
  }, [invoiceIdList]);

  useEffect(() => {
    getUserRole();
  });

  const COLUMNS = [
    {
      Header: "Invoice",
      accessor: "proper_name",
      Cell: ({ cell }) => {
        const obj = cell.row.original;

        let name = obj.proper_name;
        let lastTwo = `${name[name.length - 2]}${name[name.length - 1]}`;
        let formattedName = "";

        if (lastTwo !== "BI") {
          formattedName = `${obj.file_number}-${name[name.length - 1]}`;
        } else {
          formattedName = obj.file_number;
        }

        return (
          <>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                maxWidth: "250px",
              }}
            >
              {formattedName}
            </p>
          </>
        );
      },
    },
    {
      Header: "Master Bill of Lading",
      accessor: "master_bill_lading",
    },
    {
      Header: "Container Number(s)",
      accessor: "containers",
      Cell: ({ value }) => (
        <>
          <p
            style={{
              fontSize: "12px",
              width: "22rem",
              margin: "auto",
            }}
          >
            {value && value.length > 0 && value.split(",").length > 0 ? (
              value.split(",").length <= 2 ? (
                fewContainers(value.split(","))
              ) : (
                <ManyContainers containers={value.split(",")} />
              )
            ) : (
              <span>No containers info provided.</span>
            )}
          </p>
        </>
      ),
    },
    {
      Header: "Reference Number",
      accessor: "customer_reference_no",
      Cell: ({ value }) => {
        return (
          <>
            <p className="f-12 m-auto">{value ? value : ""}</p>
          </>
        );
      },
    },
    {
      Header: "Client",
      accessor: "account_no",
    },
    {
      Header: "Created",
      accessor: "created_at",
      Cell: ({ value }) => {
        if (value != "0000-00-00 00:00:00") {
          return format(new Date(value), "MM/dd/yyyy");
        } else {
          return "";
        }
      },
      id: "date",
      filter: "dateBetween",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: data.hasOwnProperty("result") ? data.result : data,
    },
    useSortBy
  );

  useEffect(() => {
    if (resultDeleted?.length > 0) {
      setInvoiceIdList([]);
    }
  }, [resultDeleted]);

  return (
    <>
      {!data ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="table-wrapper">
            <table {...getTableBodyProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ textAlign: "center" }}
                  >
                    {userRole == 1 && (
                      <th>
                        <div
                          className="checkbox"
                          onClick={() => toggleSelectAllInvoices()}
                        >
                          {selectInvoicesToggle == true &&
                          invoiceIdList?.length > 0 ? (
                            <p className="checked-item">✓</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </th>
                    )}

                    {headerGroup.headers.map((column) => {
                      let align = "center";
                      if (column.Header == "Invoice") {
                        align = "left";
                      }
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{ textAlign: align, cursor: "pointer" }}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.317"
                                  height="7.317"
                                  viewBox="0 0 10.317 7.317"
                                >
                                  <g transform="translate(-188.092 -344.092)">
                                    <line
                                      x2="3.75"
                                      y2="4.5"
                                      transform="translate(189.5 345.5)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="3.75"
                                      y2="4.5"
                                      transform="translate(193.25 345.5)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                  </g>
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.317"
                                  height="7.317"
                                  viewBox="0 0 10.317 7.317"
                                >
                                  <g transform="translate(1.408 1.408)">
                                    <line
                                      y1="4.5"
                                      x2="3.75"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                    />
                                    <line
                                      x1="3.75"
                                      y1="4.5"
                                      transform="translate(3.75)"
                                      fill="none"
                                      stroke="#303030"
                                      strokeLinecap="round"
                                      stroke-width="2"
                                    />
                                  </g>
                                </svg>
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} ref={invoiceTableRef}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className="invoice-table-row"
                      data-invoice-id={row?.original?.id}
                      {...row.getRowProps()}
                      style={{ textAlign: "center" }}
                      key={row?.original?.id}
                    >
                      {userRole == 1 && (
                        <td>
                          <div
                            className="checkbox"
                            data-checked={
                              invoiceIdList.find(
                                (arr_id) => arr_id == row?.original?.id
                              )
                                ? true
                                : false
                            }
                            onClick={(event) =>
                              selectInvoice(row?.original?.id, event)
                            }
                          >
                            {invoiceIdList.find(
                              (arr_id) => arr_id == row?.original?.id
                            ) != undefined ? (
                              <p
                                data-checked={
                                  invoiceIdList.find(
                                    (arr_id) => arr_id == row?.original?.id
                                  )
                                    ? true
                                    : false
                                }
                                className="checked-item"
                              >
                                ✓
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      )}

                      {row.cells.map((cell) => {
                        let align = "center";
                        let isFile = false;
                        if (cell.column.Header == "Invoice") {
                          align = "left";
                          isFile = true;
                        }

                        return (
                          <>
                            <td
                              {...cell.getCellProps()}
                              style={{
                                textAlign: align,
                              }}
                            >
                              {isFile ? (
                                <NavLink
                                  className={"invoice-file-number"}
                                  to={`/billing/${cell.row.original.id}`}
                                >
                                  {cell.render("Cell")}
                                </NavLink>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default BillingTable;
