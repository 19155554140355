import Cookies from "universal-cookie";
import { useState, useEffect } from "react";
import "../../stylesheets/Settings.scss";

import { Title } from "../../components/Title.jsx";
import {
  URL,
  LoadingIcon,
  token,
  UnsavedChanges,
  validateEmail,
  SuccessMessage,
  role,
  LoadingTable,
} from "../../components/Utils";
import {
  FaInfoCircle,
  FaEnvelope,
  FaUserAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";

import { HiLockClosed } from "react-icons/hi";
import { AiOutlineClockCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions.js";

export const Settings = () => {
  // console.log(token)
  const [globalNotif, setGlobalNotif] = useState();
  const [userSettings, setUserSettings] = useState();
  const [loading, setLoading] = useState(false);
  const [unSaved, setUnsaved] = useState(false);

  const getData = async () => {
    try {
      const resp = await fetch(`${URL}/admin/settings`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();

      if (resp.status == 200) {
        setUserSettings(data.user);

        let notification = data.global_notification;
        if (notification.message == "No global notification yet set")
          notification.message = "";
        setGlobalNotif(notification);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDateRange = (start, end) => {
    if (
      !(
        (typeof start === "undefined" && typeof end === "undefined") ||
        start == null ||
        end == null
      )
    ) {
      let startMonth = parseInt(`${start[5]}${start[6]}`);
      let startDay = parseInt(`${start[8]}${start[9]}`);
      let endMonth = parseInt(`${start[5]}${start[6]}`);
      let endDay = parseInt(`${start[8]}${start[9]}`);

      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let startMonthName = monthNames[startMonth - 1];
      let endMonthName = monthNames[endMonth - 1];

      return `${startMonthName} ${startDay} - ${endMonthName} ${endDay}`;
    } else {
      return "";
    }
  };

  const [updatedSettings, setUpdatedSettings] = useState({
    name: "",
    email: "",
    password: "",
    "New Shipment Imported": 0,
    "ISF Skipped": 0,
    "File Uploaded": 0,
    "Delivery Order Updated": 0,
    "7501 Generated": 0,
    "New User Created": 0,
    "Alert Updated": 0,
    "New Client Created": 0,
    "Shipment Placed under CBP Hold": 0,
    "Delivery Order Sent": 0,
    "Invoice Imported": 0,
    global_notification: "",
    start_date: "",
    end_date: "",
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!(typeof userSettings === "undefined")) {
      let aux = { ...updatedSettings };

      if (
        globalNotif.start_date &&
        globalNotif.start_date != "0000-00-00 00:00:00"
      ) {
        setStartDate(new Date(globalNotif.start_date));
      }
      if (globalNotif.end_date && globalNotif.end_date != "0000-00-00 00:00:00")
        setEndDate(new Date(globalNotif.end_date));

      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "New Shipment Imported"
        ).length > 0
      )
        aux["New Shipment Imported"] = userSettings.notifications.filter(
          (item) => item.notification_type == "New Shipment Imported"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "ISF Skipped"
        ).length > 0
      )
        aux["ISF Skipped"] = userSettings.notifications.filter(
          (item) => item.notification_type == "ISF Skipped"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "File Uploaded"
        ).length > 0
      )
        aux["File Uploaded"] = userSettings.notifications.filter(
          (item) => item.notification_type == "File Uploaded"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "Delivery Order Updated"
        ).length > 0
      )
        aux["Delivery Order Updated"] = userSettings.notifications.filter(
          (item) => item.notification_type == "Delivery Order Updated"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "7501 Generated"
        ).length > 0
      )
        aux["7501 Generated"] = userSettings.notifications.filter(
          (item) => item.notification_type == "7501 Generated"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "Shipment Placed under CBP Hold"
        ).length > 0
      )
        aux["Shipment Placed under CBP Hold"] =
          userSettings.notifications.filter(
            (item) => item.notification_type == "Shipment Placed under CBP Hold"
          )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "Delivery Order Sent"
        ).length > 0
      )
        aux["Delivery Order Sent"] = userSettings.notifications.filter(
          (item) => item.notification_type == "Delivery Order Sent"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "Alert Updated"
        ).length > 0
      )
        aux["Alert Updated"] = userSettings.notifications.filter(
          (item) => item.notification_type == "Alert Updated"
        )[0]["notify"];
      if (
        userSettings.notifications.filter(
          (item) => item.notification_type == "Invoice Imported"
        ).length > 0
      )
        aux["Invoice Imported"] = userSettings.notifications.filter(
          (item) => item.notification_type == "Invoice Imported"
        )[0]["notify"];

      if (role() == 1 || role() == 6) {
        if (
          userSettings.notifications.filter(
            (item) => item.notification_type == "New Client Created"
          ).length > 0
        )
          aux["New Client Created"] = userSettings.notifications.filter(
            (item) => item.notification_type == "New Client Created"
          )[0]["notify"];
        if (
          userSettings.notifications.filter(
            (item) => item.notification_type == "New User Created"
          ).length > 0
        )
          aux["New User Created"] = userSettings.notifications.filter(
            (item) => item.notification_type == "New User Created"
          )[0]["notify"];
      }

      aux.name = userSettings.name;
      aux.email = userSettings.email;
      aux.global_notification = globalNotif.message;
      aux.start_date = globalNotif.start_date;
      aux.end_date = globalNotif.end_date;
      setUpdatedSettings(aux);
    }
  }, [userSettings, globalNotif]);

  useEffect(() => {
    if (typeof userSettings !== "undefined") {
      function checkList() {
        let currentList = {};
        userSettings.notifications.forEach((item) => {
          currentList[item.notification_type] = item.notify;
        });
        let keys = [];
        let same = false;
        let count = 0;
        for (const key in currentList) {
          keys.push(key);
        }
        keys.forEach((key) => {
          if (updatedSettings[key] == currentList[key]) {
            count++;
          }
        });
        if (count == keys.length) {
          same = true;
        } else {
          same = false;
        }
        return same;
      }

      if (
        userSettings.name != updatedSettings.name ||
        userSettings.email != updatedSettings.email ||
        !checkList() ||
        updatedSettings.start_date != globalNotif.start_date ||
        updatedSettings.end_date != globalNotif.end_date ||
        updatedSettings.global_notification != globalNotif.message
      ) {
        setUnsaved(true);
      } else {
        setUnsaved(false);
      }
    }
  }, [updatedSettings, userSettings]);

  const [emailError, setEmailError] = useState(false);

  const handleEmail = (e) => {
    if (!validateEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    e.preventDefault();
    let aux = { ...updatedSettings };
    aux.email = e.target.value;
    setUpdatedSettings(aux);
  };

  const handleName = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setNameError(true);
    } else {
      setNameError(false);
      let aux = { ...updatedSettings };
      aux.name = e.target.value;
      setUpdatedSettings(aux);
    }
  };

  const handlePassword = (e) => {
    e.preventDefault();
    let aux = { ...updatedSettings };
    aux.password = e.target.value;
    setUpdatedSettings(aux);
  };

  // const handleCheckList = (e, param) => {
  //   let aux = { ...updatedSettings };

  //   switch (param) {
  //     case "New Shipment Imported":
  //       aux["New Shipment Imported"] =
  //         aux["New Shipment Imported"] == 0 ? 1 : 0;
  //       break;
  //     case "ISF Skipped":
  //       aux["ISF Skipped"] = aux["ISF Skipped"] == 0 ? 1 : 0;
  //       break;
  //     case "File Uploaded":
  //       aux["File Uploaded"] = aux["File Uploaded"] == 0 ? 1 : 0;
  //       break;
  //     case "Delivery Order Updated":
  //       aux["Delivery Order Updated"] =
  //         aux["Delivery Order Updated"] == 0 ? 1 : 0;
  //       break;
  //     case "7501 Generated":
  //       aux["7501 Generated"] = aux["7501 Generated"] == 0 ? 1 : 0;
  //       break;
  //     case "New User Created":
  //       aux["New User Created"] = aux["New User Created"] == 0 ? 1 : 0;
  //       break;
  //     case "Alert Updated":
  //       aux["Alert Updated"] = aux["Alert Updated"] == 0 ? 1 : 0;
  //       break;
  //     case "New Client Created":
  //       aux["New Client Created"] = aux["New Client Created"] == 0 ? 1 : 0;
  //       break;
  //     case "Shipment Placed under CBP Hold":
  //       aux["Shipment Placed under CBP Hold"] =
  //         aux["Shipment Placed under CBP Hold"] == 0 ? 1 : 0;
  //       break;
  //     case "Delivery Order Sent":
  //       aux["Delivery Order Sent"] = aux["Delivery Order Sent"] == 0 ? 1 : 0;
  //       break;
  //     case "Invoice Imported":
  //       aux["Invoice Imported"] = aux["Invoice Imported"] == 0 ? 1 : 0;
  //       break;
  //   }

  //   setUpdatedSettings(aux);
  // };

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  function updateLocalState() {
    let fetchedAux = { ...userSettings };
    let localAux = { ...updatedSettings };
    let fetchedNotif = { ...globalNotif };

    fetchedAux.notifications.forEach((item) => {
      item.notify = localAux[item.notification_type];
    });

    fetchedAux.name = localAux.name;
    fetchedAux.email = localAux.email;

    fetchedNotif.start_date = localAux.start_date;
    fetchedNotif.end_date = localAux.end_date;

    setUserSettings(fetchedAux);
    setGlobalNotif(fetchedNotif);
  }

  const [saved, setSaved] = useState({
    display: "none",
  });

  const uploadSettings = async (e) => {
    e.preventDefault();
    updateLocalState();
    setLoadingUpdate(true);

    if (updatedSettings?.password?.length < 1) {
      delete updatedSettings.password;
    }

    try {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/admin/settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(updatedSettings),
      });
      const data = resp.json();

      if (resp.status == 200) {
        const cookies = new Cookies();

        data.then((result) => {
          cookies.set("name", updatedSettings?.name);
        });

        setSaved({
          display: "block",
          marginBottom: "12px",
        });

        setTimeout(() => {
          setSaved({
            display: "none",
          });
        }, 5000);

        setLoadingUpdate(false);
        setUnsaved(false);
        loadingAnimation(false);
      } else {
        loadingAnimation(false);
        toast.error("Something went wrong. Please, try again in afew minutes!");
      }
    } catch (e) {
      console.log(e);
      loadingAnimation(false);
      toast.error("Something went wrong. Please, try again in afew minutes!");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleNotifNameChange = (e) => {
    e.preventDefault();
    let aux = { ...updatedSettings };
    aux.global_notification = e.target.value;
    setUpdatedSettings(aux);
  };

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    let aux = { ...updatedSettings };
    aux.start_date = start;
    aux.end_date = end;

    setStartDate(start);
    setEndDate(end);

    setUpdatedSettings(aux);
    // console.log(aux)
  };

  // console.log(token)

  const [showPassword, setShowPassword] = useState(false);

  const [nameError, setNameError] = useState();
  return (
    <>
      {loading ||
      typeof globalNotif === "undefined" ||
      typeof userSettings === "undefined" ? (
        <>
          <Title
            title={`Settings`}
            links={[
              { link: "/", title: "Home" },
              { link: "/settings", title: "Settings" },
            ]}
          />
          <div id="settings" className="page-content">
            <LoadingTable />
          </div>
        </>
      ) : (
        <>
          <Title
            title={`Settings`}
            links={[
              { link: "/", title: "Home" },
              { link: "/settings", title: "Settings" },
            ]}
          />
          <div id="settings" className="page-content">
            <form onSubmit={uploadSettings}>
              <div className="title">
                <h3>Personal Information</h3>
              </div>
              <div className="section" id="email">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="subtitle">
                      Email Address <b> </b>
                      {emailError ? "not valid" : ""}
                    </p>
                  </div>
                  <div className="right">
                    {emailError ? (
                      <p className="error-message">
                        Please enter a valid email
                      </p>
                    ) : (
                      <></>
                    )}
                    <div className={`input ${emailError ? "error" : ""}`}>
                      <div className="input-icon">
                        <FaEnvelope />
                      </div>
                      {role() != 2 ? (
                        <input
                          type="email"
                          defaultValue={userSettings["email"]}
                          onChange={handleEmail}
                        />
                      ) : (
                        <input
                          type="email"
                          readOnly
                          placeholder={userSettings["email"]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="section" id="email">
                <div className="section-wrapper">
                  <div className="left">
                    <p className="subtitle">Full Name</p>
                  </div>
                  <div className="right">
                    {nameError ? (
                      <p className="error-message">Please enter a valid name</p>
                    ) : (
                      <></>
                    )}
                    <div className={`input ${nameError ? "error" : ""}`}>
                      <div className="input-icon">
                        <FaUserAlt />
                      </div>
                      {role() != 2 ? (
                        <input
                          type="text"
                          defaultValue={userSettings["name"]}
                          onChange={handleName}
                        />
                      ) : (
                        <input
                          type="text"
                          readOnly
                          placeholder={userSettings["name"]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {role() != 2 ? (
                <div className="section" id="email">
                  <div className="section-wrapper">
                    <div className="left">
                      <p className="subtitle">Password</p>
                    </div>
                    <div className="right">
                      <div className="input">
                        <div className="input-icon">
                          <HiLockClosed />
                        </div>
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          onChange={handlePassword}
                        />
                        <div
                          className="input-icon"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* <div className="notifications">
                <div className="top">
                  <h3>Notifications</h3>
                </div>
                <div className="options">
                  <div className="option-1">
                    <p className="notification-option-title">
                      Select the updates you would like to receive
                    </p>
                    <div className="checkboxes">
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "New Shipment Imported");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["New Shipment Imported"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>Shipment Imported</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "ISF Skipped");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["ISF Skipped"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>ISF not filed by AFB</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "File Uploaded");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["File Uploaded"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>File Uploaded</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "Delivery Order Updated");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["Delivery Order Updated"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>Delivery Order Updated</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "7501 Generated");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["7501 Generated"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>7501 Generated</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "Invoice Imported");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["Invoice Imported"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>Invoice Imported</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "Alert Updated");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["Alert Updated"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>Alert Updated</p>
                      </div>
                      {role() == 1 && (
                        <>
                          <div
                            className="notif-box"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckList(e, "New User Created");
                            }}
                          >
                            <div className="checkbox">
                              {updatedSettings["New User Created"] == 0 ? (
                                ""
                              ) : (
                                <p className="checked-item">✓</p>
                              )}
                            </div>
                            <p>New User Created</p>
                          </div>
                          <div
                            className="notif-box"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckList(e, "New Client Created");
                            }}
                          >
                            <div className="checkbox">
                              {updatedSettings["New Client Created"] == 0 ? (
                                ""
                              ) : (
                                <p className="checked-item">✓</p>
                              )}
                            </div>
                            <p>New Client Created</p>
                          </div>
                        </>
                      )}
                      {role() == 6 && (
                        <>
                          <div
                            className="notif-box"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckList(e, "New User Created");
                            }}
                          >
                            <div className="checkbox">
                              {updatedSettings["New User Created"] == 0 ? (
                                ""
                              ) : (
                                <p className="checked-item">✓</p>
                              )}
                            </div>
                            <p>New User Created</p>
                          </div>
                          <div
                            className="notif-box"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckList(e, "New Client Created");
                            }}
                          >
                            <div className="checkbox">
                              {updatedSettings["New Client Created"] == 0 ? (
                                ""
                              ) : (
                                <p className="checked-item">✓</p>
                              )}
                            </div>
                            <p>New Client Created</p>
                          </div>
                        </>
                      )}
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "Shipment Placed under CBP Hold");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["Shipment Placed under CBP Hold"] ==
                          0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>Shipment Placed on CBP Hold</p>
                      </div>
                      <div
                        className="notif-box"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCheckList(e, "Delivery Order Sent");
                        }}
                      >
                        <div className="checkbox">
                          {updatedSettings["Delivery Order Sent"] == 0 ? (
                            ""
                          ) : (
                            <p className="checked-item">✓</p>
                          )}
                        </div>
                        <p>Delivery Order Sent</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {role() == 1 && (
                <div className="alerts">
                  <div className="top">
                    <h3>Alerts</h3>
                  </div>
                  <div className="global-alert">
                    <div className="left">
                      <p className="subtitle">Global Alert</p>
                      <p className="light-text">
                        Notify your customer regarding any changes and updates
                      </p>
                    </div>
                    <div className="right">
                      <div className="alert text">
                        <div className="icon">
                          <FaInfoCircle />
                        </div>
                        <div className="body-text">
                          <input
                            type="text"
                            defaultValue={globalNotif.message}
                            onChange={handleNotifNameChange}
                          />
                        </div>
                      </div>
                      <div className="alert date">
                        <div className="icon">
                          <AiOutlineClockCircle />
                        </div>
                        <div className="body-text">
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onDateRangeChange}
                            isClearable={true}
                            dateFormat="MMMM dd"
                            placeholderText={getDateRange(startDate, endDate)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {unSaved ? <UnsavedChanges /> : <></>}
              <SuccessMessage style={saved} />
              <button
                type="submit"
                style={{
                  width: "90px",
                  height: "28px",
                  pointerEvents: emailError || nameError ? "none" : "",
                  opacity: emailError || nameError ? ".75" : "1",
                }}
              >
                {loadingUpdate ? <LoadingIcon /> : "Save"}
              </button>
            </form>
          </div>
        </>
      )}
    </>
  );
};
export default Settings;
