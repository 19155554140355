import loader from '../assets/loader.gif'
const Loading = () => {
  return (
    <>
      <div style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <h1 style={{
          color: "var(--green)",
          fontSize: "5rem",
          textAlign: "center"
        }}>
          Loading please wait...
        </h1>
      </div>
    </>
  )

}

export default Loading;