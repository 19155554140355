import "../../stylesheets/Login.scss";
import emailIcon from "../../assets/loginAssets/email-icon.svg";
import LoadingIcon from '../LoadingIcon';
import passwordLogo from "../../assets/loginAssets/password-logo.svg";

import "../../stylesheets/Login.scss";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useState } from "react";
import Cookies from "universal-cookie";

async function resetPass(credentials, token) {
  console.log(credentials);
  const URL = process.env.REACT_APP_API_URL;
  return fetch(
    URL + "/reset-password/" + token,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      Accept: 'application/json',
      body: JSON.stringify(credentials),
    }
  ).then((data) => data.json());
}
export const ResetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [wrongStyle, setWrongStyle] = useState([]);
  const [valid, setValid] = useState(false);
  const [errors, setErrors] = useState({email: false, password: false, passwordConfirm: false});
  const [loading, setLoading] = useState(false);

  const { token } = useParams();

  const setErrorStyle = () => {
    setWrongStyle({
      border: "1px solid red",
    });
  };

  let [errorMsg, setErrorMsg] = useState("");

  const validate = () => {
    setErrorMsg("");
    setWrongStyle({});

    let emptyUser = false;
    let emptyPass = false;
    let emptyPassConfirm = false;
    typeof email === "undefined" || email.length <= 0
      ? (emptyUser = true)
      : (emptyUser = false);
    typeof password === "undefined" || password.length <= 0
      ? (emptyPass = true)
      : (emptyPass = false);
    typeof passwordConfirm === "undefined" || passwordConfirm.length <= 0
      ? (emptyPassConfirm = true)
      : (emptyPassConfirm = false);

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const curErrors = {
      email: false,
      password: false,
      passwordConfirm: false
    }

    if (emptyUser) {
      setErrorMsg("Email cannot be empty");
      setErrorStyle();
      curErrors.email = true;
    }
    if(!email.match(validRegex)) {
      emptyUser = true;
      setErrorMsg("Please enter a valid email address");
      setErrorStyle();
      curErrors.email = true;
    }
    if (emptyPass) {
      setErrorMsg("Password cannot be empty");
      setErrorStyle();
      curErrors.password = true;
    }
    if(password?.length < 6) {
      emptyPass = true;
      setErrorMsg("Password should be at least 6 characters long");
      setErrorStyle();
      curErrors.password = true;
    }
    if (emptyPassConfirm) {
      setErrorMsg("Please confirm your password");
      setErrorStyle();
      curErrors.passwordConfirm = true;
    }
    if(passwordConfirm != password) {
      setErrorMsg("Password and confirm password should match");
      setErrorStyle();
      curErrors.passwordConfirm = true;
    }
    if (emptyPass && emptyUser) {
      setErrorMsg("Please enter a valid email and password");
      setErrorStyle();
      console.log(curErrors);
    }
    setErrors(curErrors);

    if(errorMsg.length > 0) {
      return false;
    }
    return true;
  };

  //Where the form's data arrives and gets validated
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      const cookies = new Cookies();
      const response = await resetPass({
        email,
        password,
        password_confirmation: passwordConfirm
      }, token);

      console.log(response);

      if(response?.message == "Your password has been changed!") {
        setLoading(false);
        navigate("/");
      } else {
        setLoading(false);
        setErrorMsg(response?.message);
      }
    }
  };
  return (
    <>
      <div id="login">
        <img src={require("../../assets/atlantic-main-logo.png")} alt="" id="main-logo"/>
        <p className="login-title">ATLANTIC FREIGHT BROKERS</p>
        {/**ERROR MESSAGE */ <p id="wrong-msg">{errorMsg}</p>}
        <form className="inputs" onSubmit={handleSubmit}>
          <div
            style={
              typeof email === "undefined" || email.length <= 0 || !valid || errors.email
                ? wrongStyle
                : {}
            }
            className="input-container"
          >
            <img src={emailIcon} alt="" />
            <input
              type="text"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div
            style={
              typeof password === "undefined" || password.length <= 0 || !valid || errors.password
                ? wrongStyle
                : {}
            }
            className="input-container"
          >
            <img src={passwordLogo} alt="" />
            <input
              type="password"
              placeholder="New Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div
            style={
              typeof passwordConfirm === "undefined" || passwordConfirm.length <= 0 || !valid || errors.passwordConfirm
                ? wrongStyle
                : {}
            }
            className="input-container"
          >
            <img src={passwordLogo} alt="" />
            <input
              type="password"
              placeholder="Confirm New Password"
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
          {loading ? <button className="button"><LoadingIcon /></button> : <input type="submit" value="Submit" className="button" />}
        </form>
        <a href="/forgot-password" className="forgot-pass">Forgot password</a>
      </div>
    </>
  );
};
